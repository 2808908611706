import moment, { MomentInput } from "moment";

const getFormattedDateTime = (
  date: MomentInput,
  time: String,
  timeZoneOffset?: string
) => {
  const d = moment(date).format("MM/DD/YYYY");
  let t = "";

  if (timeZoneOffset) {
    t = moment(d + " " + time + " " + timeZoneOffset)
      .utc()
      .format();
  } else {
    t = moment(d + " " + time)
      .utc()
      .format();
  }

  return t;
};

const getFormattedTime = (time: string) => {
  const temp = time.split("");
  temp.splice(2, 0, ":");
  return temp.join("");
};

const getFormattedDate = (date: string) => {
  const D = moment(moment(date).format("MM.DD.YYYY HH:mm:ss")).utc().format();
  return D;
};

export { getFormattedDateTime, getFormattedTime, getFormattedDate };
