import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";

import { BookingsQuery } from "../../Interfaces/Bookings";

import { getFormattedDateTime } from "../../utils/FormatTime";

import { GetBookingQuery } from "../../Query/Bookings";

import BackButton from "../../images/back_white@2x.png";

import * as BookingSlotViewStyle from "./BookingSlotsView.module.scss";
import moment from "moment";

interface Props {
  date: string;
  parkingArea: string;
  onClickBack: () => void;
}

interface Slot {
  start: string;
  end: string;
  isTaken: boolean;
  color?: string[];
  bookingId?: string[];
  regNo?: string[];
}

interface TakeSlot {
  start: string;
  end: string;
  isTaken: boolean;
  color: string;
  bookingId: string;
  regNo: string;
}

interface ColorCode {
  color: string;
  bookingId: string;
  regNo: string;
}

const slots: Slot[] = [
  { start: "1:00", end: "2:00", isTaken: false },
  { start: "2:00", end: "3:00", isTaken: false },
  { start: "3:00", end: "4:00", isTaken: false },
  { start: "4:00", end: "5:00", isTaken: false },
  { start: "5:00", end: "6:00", isTaken: false },
  { start: "6:00", end: "7:00", isTaken: false },
  { start: "7:00", end: "8:00", isTaken: false },
  { start: "8:00", end: "9:00", isTaken: false },
  { start: "9:00", end: "10:00", isTaken: false },
  { start: "10:00", end: "11:00", isTaken: false },
  { start: "11:00", end: "12:00", isTaken: false },
  { start: "12:00", end: "13:00", isTaken: false },
  { start: "13:00", end: "14:00", isTaken: false },
  { start: "14:00", end: "15:00", isTaken: false },
  { start: "15:00", end: "16:00", isTaken: false },
  { start: "16:00", end: "17:00", isTaken: false },
  { start: "17:00", end: "18:00", isTaken: false },
  { start: "18:00", end: "19:00", isTaken: false },
  { start: "19:00", end: "20:00", isTaken: false },
  { start: "20:00", end: "21:00", isTaken: false },
  { start: "21:00", end: "22:00", isTaken: false },
  { start: "22:00", end: "23:00", isTaken: false },
  { start: "23:00", end: "24:00", isTaken: false },
];

const Index = ({ date, onClickBack, parkingArea }: Props) => {
  const [bookingSlots, setBookingSlots] = useState<Slot[]>(slots);
  const [overLapsedData, setOverLapsedData] = useState<TakeSlot[][]>();
  const [colorCodes, setColorCodes] = useState<ColorCode[]>();

  const { data, loading, error } = useQuery<BookingsQuery>(GetBookingQuery, {
    variables: {
      status: ["BOOKED"],
      bookFrom: [{ after: getFormattedDateTime(date, "00:00") }],
      bookTo: [
        {
          before: getFormattedDateTime(date, "24:00"),
        },
      ],
      parkingArea: parkingArea,
    },
    fetchPolicy: "network-only",
  });

  const getRandomColor = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  };

  const getDateInNumber = (date: string) => {
    return parseInt(moment(date).format("H"));
  };

  const getSlotInNumber = (slot: string) => {
    return parseInt(slot.split(":")[0]);
  };

  useEffect(() => {
    if (data?.bookings.collection) {
      const temp: TakeSlot[] = data.bookings.collection.map(
        ({ id, bookFrom, bookTo, regNo }) => {
          return {
            end:
              moment(bookTo).format("H:mm") === "23:59"
                ? "24:00"
                : moment(bookTo).format("H:mm"),
            start: moment(bookFrom).format("H:mm"),
            bookingId: id,
            isTaken: true,
            color: getRandomColor(),
            regNo,
          };
        }
      );

      const t: Slot[] = bookingSlots.map((bSlot) => {
        const s = temp.reduce((a, tt) => {
          if (
            getSlotInNumber(tt.start) === getSlotInNumber(bSlot.start) ||
            getSlotInNumber(tt.end) === getSlotInNumber(bSlot.end)
          ) {
            return [
              ...a,
              {
                ...bSlot,
                bookingId: tt.bookingId,
                color: tt.color,
                isTaken: tt.isTaken,
                regNo: tt.regNo,
              },
            ];
          } else return a;
        }, Array());

        if (s.length) {
          return {
            ...s[0],
            bookingId: s.map(({ bookingId }) => bookingId),
            regNo: s.map(({ regNo }) => regNo),
            color: s.map(({ color }) => color),
          };
        } else return bSlot;
      });

      setBookingSlots(t);

      const dfsf = t.filter(({ bookingId }) => bookingId?.length);

      const cc: ColorCode[] = [];
      const usedBookingID: string[] = [];

      dfsf.forEach((d) => {
        d.bookingId?.forEach((id, i) => {
          if (!usedBookingID.includes(id)) {
            usedBookingID.push(id);
            cc.push({
              bookingId: id,
              color: d.color ? d.color[i] : "",
              regNo: d.regNo ? d.regNo[i] : "",
            });
          }
        });
      });

      setColorCodes(cc);

      getOverlapsedData(t);
    }
  }, [data]);

  const getOverlapsedData = (data: Slot[]) => {
    const temp: TakeSlot[][] = [];

    const overlapsed = data.filter(
      ({ bookingId, color }) =>
        bookingId && bookingId?.length > 1 && color && color?.length > 1
    );

    overlapsed.forEach((ovlp) => {
      ovlp.bookingId
        ?.filter((_, i) => i !== 0)
        ?.forEach((id, i) => {
          const t: TakeSlot[] = data.map((d) => {
            if (
              JSON.stringify(ovlp.bookingId) === JSON.stringify(d.bookingId)
            ) {
              return {
                ...d,
                bookingId: id,
                color: ovlp.color ? ovlp.color[i + 1] : "",
                regNo: ovlp.regNo ? ovlp.regNo[i + 1] : "",
              };
            }
            return {
              ...d,
              bookingId: "",
              color: "",
              regNo: "",
            };
          });
          temp.push(t);
        });
    });

    setOverLapsedData(temp);
  };

  return (
    <div className={BookingSlotViewStyle.container}>
      <div className={`${BookingSlotViewStyle.header} grediant1`}>
        <button
          onClick={onClickBack}
          className={BookingSlotViewStyle.btn__back}
        >
          <img src={BackButton} alt="back" width={24} height={24} />
          <span>Back To Calender</span>
        </button>
      </div>
      <div className={BookingSlotViewStyle.content}>
        <div className={BookingSlotViewStyle.card__content}>
          {bookingSlots &&
            bookingSlots?.map(({ bookingId, color, start, end }) => (
              <div
                key={start}
                className={BookingSlotViewStyle.card__slot__item}
              >
                <span
                  key={`${bookingId}${start}${end}`}
                  className={BookingSlotViewStyle.card__content__slot}
                  {...{
                    style: {
                      backgroundColor: color?.length ? color[0] : "green",
                    },
                  }}
                >
                  {start}-{end}
                </span>
              </div>
            ))}
        </div>
        {overLapsedData &&
          overLapsedData.map((data) => (
            <div className={BookingSlotViewStyle.card__content}>
              {data.map(({ start, bookingId, color, isTaken, end }) => (
                <div
                  key={start}
                  className={BookingSlotViewStyle.card__slot__item}
                >
                  <span
                    key={`${bookingId}${start}${end}`}
                    className={BookingSlotViewStyle.card__content__slot}
                    {...{
                      style: {
                        backgroundColor: isTaken ? color : "green",
                      },
                    }}
                  >
                    {start}-{end}
                  </span>
                </div>
              ))}
            </div>
          ))}
      </div>
      <div className={BookingSlotViewStyle.footer}>
        {colorCodes &&
          colorCodes.map(({ regNo, bookingId, color }) => (
            <div key={`${bookingId}`} className={BookingSlotViewStyle.color}>
              <span
                className={BookingSlotViewStyle.color__code}
                {...{ style: { backgroundColor: color } }}
              >
                &nbsp;
              </span>
              <span className={BookingSlotViewStyle.color__reg}>{regNo}</span>
            </div>
          ))}
        <div className={BookingSlotViewStyle.color}>
          <span
            className={BookingSlotViewStyle.color__code}
            {...{ style: { backgroundColor: "green" } }}
          >
            &nbsp;
          </span>
          <span className={BookingSlotViewStyle.color__reg}>Available</span>
        </div>
      </div>
    </div>
  );
};

export default Index;
