// extracted by mini-css-extract-plugin
export var container = "BookingSlotsView-module--container--lUKYp";
export var header = "BookingSlotsView-module--header--5Uows";
export var content = "BookingSlotsView-module--content--D6cuW";
export var card = "BookingSlotsView-module--card--eXPtY";
export var card__header = "BookingSlotsView-module--card__header--xB6Ek";
export var card__content = "BookingSlotsView-module--card__content--LuITJ";
export var card__content__slot = "BookingSlotsView-module--card__content__slot--3v37f";
export var card__slot__item = "BookingSlotsView-module--card__slot__item--Au0TO";
export var btn__back = "BookingSlotsView-module--btn__back---Ak-9";
export var footer = "BookingSlotsView-module--footer--gv8WS";
export var color = "BookingSlotsView-module--color--T3zJM";
export var color__code = "BookingSlotsView-module--color__code--RIoBv";
export var color__reg = "BookingSlotsView-module--color__reg--62aK6";