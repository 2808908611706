// extracted by mini-css-extract-plugin
export var card = "BookingListCard-module--card--xb2Ia";
export var card__header = "BookingListCard-module--card__header--kmczn";
export var card__content__grid = "BookingListCard-module--card__content__grid--wih+X";
export var card__content__grid__reg = "BookingListCard-module--card__content__grid__reg--6LHQj";
export var card__content__grid__price = "BookingListCard-module--card__content__grid__price--oSzEv";
export var card__content__grid__detail = "BookingListCard-module--card__content__grid__detail--X2Uel";
export var card__content = "BookingListCard-module--card__content--erUgM";
export var card__content__reg = "BookingListCard-module--card__content__reg--il8W-";
export var card__content__detail = "BookingListCard-module--card__content__detail--yeW-R";
export var card__content__price = "BookingListCard-module--card__content__price--jf2Uo";