import React, { useEffect, useState } from "react";

import { gql, useQuery } from "@apollo/client";

import BookingListCard from "../BookingListCard";
import Modal from "../Modal";
import BookingFilter from "../Modals/BookingsFilter";
import CalanderView from "../CalanderView";
import BookingSlotsView from "../BookingSlotsView";
import Loader from "../Loader";

import { GetBookingQuery } from "../../Query/Bookings";
import { Booking, BookingsQuery } from "../../Interfaces/Bookings";

import FitlerIcon from "../../images/filter_icon2@2x.png";
import BookingListIcon from "../../images/booking_list_icon_w@2x.png";

import * as BookingListStyle from "./BookingList.module.scss";
import { GetParkingAreaQuery as IGetParkingAreaQuery } from "../../Interfaces/ParkingArea";

import Logger from "../../utils/Logger";

interface FilterData {
  parkingArea?: string;
  fromDate?: string;
  toDate?: string;
  regNo?: string;
  name?: string;
  vehicleType?: SelectedVehicleType;
}

type SelectedVehicleType = ("2W" | "4W" | "OTHER")[];

const parkingAreaQuery = gql`
  query ParkingAreas($status: [String], $category: [String]) {
    parkingAreas(status_code_list: $status, category_code_list: $category) {
      collection {
        id
        name
        category {
          code
        }
      }
    }
  }
`;

const Index = () => {
  const [page, setPage] = useState(1);
  const [isGridView, setIsGridView] = useState(false);
  const [viwType, setViwType] = useState<"CALANDER" | "LIST" | "SINGLELIST">(
    "CALANDER"
  );
  const [filterData, setFilterData] = useState<FilterData>();
  const [isFilter, setIsFilter] = useState(false);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [slotsDate, setSlotsDate] = useState<string>();

  const { data, loading, error, fetchMore } = useQuery<BookingsQuery>(
    GetBookingQuery,
    {
      variables: {
        status: ["BOOKED"],
        order: [{ createdAt: "DESC" }],
        bookFrom:
          filterData?.toDate || filterData?.fromDate
            ? [
                {
                  before: filterData?.toDate,
                },
                {
                  after: filterData?.fromDate,
                },
              ]
            : undefined,
        parkingArea:
          filterData?.parkingArea === "all"
            ? undefined
            : filterData?.parkingArea,
        parkingArea_name:
          filterData?.name === "" ? undefined : filterData?.name,
        regNo: filterData?.regNo === "" ? undefined : filterData?.regNo,
        vehicle_code_list: filterData?.vehicleType,
      },
      fetchPolicy: "network-only",
    }
  );

  const {
    data: parkingAreas,
    loading: loadingArea,
    error: araError,
  } = useQuery<IGetParkingAreaQuery>(parkingAreaQuery, {
    variables: {
      status: ["ACTIVE", "DEACTIVE"],
      category: ["PARKING", "CHARGING"],
    },
  });

  useEffect(() => {
    if (viwType === "CALANDER" && parkingAreas?.parkingAreas?.collection) {
      setFilterData({
        parkingArea: parkingAreas?.parkingAreas?.collection[0]?.id,
      });
    }
  }, [parkingAreas?.parkingAreas?.collection]);

  useEffect(() => {
    if (data) {
      setBookings(data?.bookings?.collection);
    }
  }, [data]);

  useEffect(() => {
    if (error || araError) {
      Logger.showError(error || araError);
    }
  }, [error, araError]);

  return (
    <>
      <div className={BookingListStyle.container}>
        <div className={BookingListStyle.header}>
          <h4>Booking List</h4>
          {viwType === "CALANDER" && (
            <select
              className={BookingListStyle.select}
              defaultValue={parkingAreas?.parkingAreas?.collection[0]?.id}
              onChange={(e) => {
                setFilterData({ parkingArea: e.target.value });
              }}
            >
              {parkingAreas?.parkingAreas?.collection?.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          )}
          <div className={BookingListStyle.header__btns}>
            <button
              className={BookingListStyle.header__btns__type}
              onClick={(e) => {
                setViwType(viwType === "CALANDER" ? "LIST" : "CALANDER");
              }}
            >
              <img
                src={BookingListIcon}
                alt="booking List"
                width={20}
                height={20}
              />
              <span>
                {viwType === "LIST" ? "Booking Calander" : "Booking List"}
              </span>
            </button>
            {viwType === "LIST" && (
              <button onClick={() => setIsFilter(true)}>
                <img src={FitlerIcon} alt="fitler" width={28} height={28} />
              </button>
            )}
          </div>
        </div>

        {viwType === "LIST" && (
          <>
            <div
              className={
                isGridView
                  ? BookingListStyle.content__grid
                  : BookingListStyle.content__list
              }
            >
              {bookings?.length ? (
                bookings?.map((booking) => (
                  <BookingListCard
                    key={booking.id}
                    isGridView
                    booking={booking}
                  />
                ))
              ) : (
                <>
                  <span className="color-primary boldText textMd">
                    No Booking Found
                  </span>
                </>
              )}
            </div>
            {data?.bookings && data?.bookings.paginationInfo?.lastPage > page && (
              <div>
                <button
                  onClick={() => {
                    fetchMore({
                      variables: {
                        page: page + 1,
                      },
                    })
                      .then((res) => {
                        setBookings([
                          ...bookings,
                          ...res.data.bookings.collection,
                        ]);
                        setPage(page + 1);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                >
                  Load More
                </button>
              </div>
            )}
          </>
        )}
        {viwType === "CALANDER" && (
          <CalanderView
            bookings={data?.bookings.collection}
            onClickDate={(date) => {
              setSlotsDate(date);
              setViwType("SINGLELIST");
            }}
          />
        )}
        {viwType === "SINGLELIST" && slotsDate && filterData?.parkingArea && (
          <BookingSlotsView
            date={slotsDate}
            parkingArea={filterData?.parkingArea}
            onClickBack={() => setViwType("CALANDER")}
          />
        )}
      </div>
      {isFilter && (
        <Modal
          title="Filter"
          isOpen={isFilter}
          onClose={() => setIsFilter(false)}
          showHeader
        >
          <BookingFilter
            formData={filterData}
            onClose={() => setIsFilter(false)}
            onSave={(data) => {
              setFilterData(data);
              setIsFilter(false);
            }}
            parkingAreas={parkingAreas?.parkingAreas.collection}
          />
        </Modal>
      )}
      <Loader isLoading={loading || loadingArea} />
    </>
  );
};

export default Index;
