import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { ParkingArea } from "../../../Interfaces/ParkingArea";

import BikeIconActive from "../../../images/twowheeler_64_active@2x.png";
import BikeIcon from "../../../images/twowheeler_64@2x.png";
import CarIcon from "../../../images/car_64@2x.png";
import CarIconActive from "../../../images/car_64_active@2x.png";
import BusIconActive from "../../../images/bus_64_active@2x.png";
import BusIcon from "../../../images/bus_64@2x.png";

import * as BookingFilterStyle from "./BookingFilter.module.scss";

interface FilterData {
  parkingArea?: string;
  fromDate?: string;
  toDate?: string;
  regNo?: string;
  name?: string;
  vehicleType?: SelectedVehicleType;
}

interface Props {
  formData?: FilterData;
  parkingAreas?: ParkingArea[];
  onSave: (data: FilterData | undefined) => void;
  onClose: () => void;
}

type SelectedVehicleType = ("2W" | "4W" | "OTHER")[];

const Index = ({ onSave, formData, onClose, parkingAreas }: Props) => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: { ...formData },
  });

  const [selectedVehicleType, setSelectedVehicleType] =
    useState<SelectedVehicleType>(
      formData?.vehicleType ? formData.vehicleType : []
    );

  const onHandleSubmit: SubmitHandler<FilterData> = (data) => {
    onSave({ ...data, vehicleType: selectedVehicleType });
    reset();
  };

  const vehicleType: SelectedVehicleType = ["2W", "4W", "OTHER"];

  return (
    <form
      onSubmit={handleSubmit(onHandleSubmit)}
      className={BookingFilterStyle.form}
    >
      <button
        className={`${BookingFilterStyle.action__btn} ${BookingFilterStyle.action__btn__clearAll}`}
        onClick={() => {
          reset();
          onSave(undefined);
        }}
      >
        Clear All
      </button>
      <select
        placeholder="All"
        {...register("parkingArea")}
        className={BookingFilterStyle.form__input}
      >
        <option value="all">All</option>
        {parkingAreas?.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </select>
      <div className={BookingFilterStyle.form__group}>
        <input
          type="date"
          {...register("fromDate")}
          className={BookingFilterStyle.form__input}
          placeholder="From"
        />
        <input
          type="date"
          {...register("toDate")}
          className={BookingFilterStyle.form__input}
          placeholder="To"
        />
      </div>
      <input
        type="text"
        placeholder="Registration Number Starts With"
        {...register("regNo")}
        className={BookingFilterStyle.form__input}
      />
      <input
        type="text"
        placeholder="Name Being With"
        {...register("name")}
        className={BookingFilterStyle.form__input}
      />
      <div className={BookingFilterStyle.form__vehicle}>
        <span>Select Vehicle</span>
        <div className={BookingFilterStyle.form__vehicle__items}>
          {vehicleType.map((type) => (
            <div key={type}>
              <label htmlFor={type}>
                {type === "2W" && (
                  <img
                    src={
                      selectedVehicleType.includes("2W")
                        ? BikeIconActive
                        : BikeIcon
                    }
                    alt="bike"
                    width={56}
                    height={56}
                  />
                )}
              </label>
              <label htmlFor={type}>
                {type === "4W" && (
                  <img
                    src={
                      selectedVehicleType.includes("4W")
                        ? CarIconActive
                        : CarIcon
                    }
                    alt="bike"
                    width={56}
                    height={56}
                  />
                )}
              </label>
              <label htmlFor={type}>
                {type === "OTHER" && (
                  <img
                    src={
                      selectedVehicleType.includes("OTHER")
                        ? BusIconActive
                        : BusIcon
                    }
                    alt="bike"
                    width={56}
                    height={56}
                  />
                )}
              </label>
              <input
                type="checkbox"
                id={type}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedVehicleType([...selectedVehicleType, type]);
                  } else {
                    setSelectedVehicleType(
                      selectedVehicleType.filter((svt) => svt !== type)
                    );
                  }
                }}
                hidden
              />
            </div>
          ))}
        </div>
      </div>
      <div className={BookingFilterStyle.action}>
        <button
          onClick={onClose}
          className={`${BookingFilterStyle.action__btn} ${BookingFilterStyle.action__btn__close}`}
        >
          Close
        </button>
        <button
          className={`${BookingFilterStyle.action__btn} ${BookingFilterStyle.action__btn__save}`}
        >
          Ok
        </button>
      </div>
    </form>
  );
};

export default Index;
