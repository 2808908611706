import { gql } from "@apollo/client";

export const GetBookingQuery = gql`
  query bookings(
    $areaUserId: Int
    $bookFrom: [BookingFilter_bookFrom]
    $bookTo: [BookingFilter_bookTo]
    $parkingArea: String
    $parkingArea_name: String
    $regNo: String
    $vehicle_code_list: [String]
    $page: Int
    $status: [String]
    $order: [BookingFilter_order]
  ) {
    bookings(
      parkingArea_user_id: $areaUserId
      bookFrom: $bookFrom
      bookTo: $bookTo
      parkingArea: $parkingArea
      parkingArea_name: $parkingArea_name
      regNo: $regNo
      vehicle_code_list: $vehicle_code_list
      status_code_list: $status
      order: $order
      page: $page
    ) {
      collection {
        id
        userName
        parkingArea {
          id
          name
        }
        userName
        userContactNumber
        userEmail
        bookFrom
        bookTo
        regNo
        status {
          code
        }
        vehicle {
          code
        }
        amount
      }
      paginationInfo {
        itemsPerPage
        lastPage
        totalCount
      }
    }
  }
`;
