import React from "react";
import { Booking } from "../../Interfaces/Bookings";

import BusIcon from "../../images/bus_64@2x.png";
import CarIcon from "../../images/bus_64@2x.png";
import BikeIcon from "../../images/twowheeler_64.png";
import TickIcon from "../../images/tick_icon3@2x.png";

import * as BookingListCardStyle from "./BookingListCard.module.scss";
import moment from "moment";

interface Props {
  booking: Booking;
  isGridView: boolean;
}

const Index = ({ booking, isGridView }: Props) => {
  const getImgByVehicle = (vehicleType: "2W" | "4W" | "OTHER") => {
    if (vehicleType === "2W")
      return <img src={BikeIcon} alt="two wheeler" width={64} height={64} />;
    if (vehicleType === "4W")
      return <img src={CarIcon} alt="two wheeler" width={64} height={64} />;
    if (vehicleType === "OTHER")
      return <img src={BusIcon} alt="two wheeler" width={64} height={64} />;
  };

  return (
    <div className={BookingListCardStyle.card}>
      <div className={`${BookingListCardStyle.card__header} grediant1`}>
        <h6>{booking.parkingArea.name}</h6>
      </div>
      <div
        className={
          isGridView
            ? BookingListCardStyle.card__content__grid
            : BookingListCardStyle.card__content
        }
      >
        <div
          className={
            isGridView
              ? BookingListCardStyle.card__content__grid__reg
              : BookingListCardStyle.card__content__reg
          }
        >
          <div>{getImgByVehicle(booking.vehicle.code)}</div>
          <span>{booking.regNo}</span>
        </div>
        <div
          className={`${
            isGridView
              ? BookingListCardStyle.card__content__grid__detail
              : BookingListCardStyle.card__content__detail
          } grediant1`}
        >
          <span>{moment(booking.bookFrom).format("DD/MM/YYYY")}</span>
          <span>
            {moment(booking.bookFrom).format("hh:mm")}-
            {moment(booking.bookTo).format("hh:mm")}
          </span>
        </div>
        <div
          className={
            isGridView
              ? BookingListCardStyle.card__content__grid__price
              : BookingListCardStyle.card__content__price
          }
        >
          <img src={TickIcon} alt="status" width={32} height={32} />
          <span>&#8377;&nbsp;{booking.amount}</span>
        </div>
      </div>
    </div>
  );
};

export default Index;
