import React, { useEffect, useState } from "react";

import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // a

import Img from "../../images/parkgenie_logo_forvideo@2x.png";

import * as CalanderViewStyle from "./CalanderView.module.scss";
import { Booking } from "../../Interfaces/Bookings";
import moment from "moment";

interface Props {
  bookings?: Booking[];
  onClickDate: (date: string) => void;
}

const Index = ({ bookings, onClickDate }: Props) => {
  const [uniqueBooking, setUniqueBooking] = useState<string[]>();
  const renderEventContent = (e) => {
    return (
      <button
        onClick={() => {
          onClickDate(e.event.start.toISOString());
        }}
      >
        <img src={Img} alt="" />
      </button>
    );
  };

  useEffect(() => {
    if (bookings) {
      setUniqueBooking([
        ...new Set(
          bookings.map(({ bookFrom }) => moment(bookFrom).format("YYYY-MM-DD"))
        ),
      ]);
    }
  }, [bookings]);

  return (
    <div className={CalanderViewStyle.container}>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        height={400}
        events={uniqueBooking?.map((d) => {
          return {
            date: d,
            title: "fcff",
          };
        })}
        eventContent={renderEventContent}
        dateClick={(e) => {
          onClickDate(e.date.toISOString());
        }}
      />
    </div>
  );
};

export default Index;
