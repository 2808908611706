import React from "react";

import Layout from "../Components/Layout";
import BookingList from "../Components/BookingList";

const BookingsPage = () => {
  return (
    <Layout showHeader isPrivate>
      <BookingList />
    </Layout>
  );
};

export default BookingsPage;
